<template>

  <v-expansion-panels style="border-radius: 0px;">
    <EspansionMontages v-if="user.role === 'master'" />
    <EspansionTasks v-if="user.role === 'master'" />
    <EspansionHourlyWorks />
  </v-expansion-panels>

</template>

<script>
import EspansionMontages from './Components/Montages.vue'
import EspansionTasks from './Components/Tasks.vue'
import EspansionHourlyWorks from './Components/HourlyWorks.vue'

export default {
  components: {
    EspansionMontages,
    EspansionTasks,
    EspansionHourlyWorks
  },
  data: () => ({}),
  methods: {},
  computed: {
    hourlyWorks() {
      return []
    },
    costHourlyWorks() {
      if (!this.hourlyWorks || this.hourlyWorks.length === 0) {
        return false
      }
      let price = 0
      this.hourlyWorks.map(work => {
        if (work.montagersInfo[this.user.id] && work.montagersInfo[this.user.id].costMontage) {
          price = price + Number(work.montagersInfo[this.user.id].costMontage)
        }
      })
      return `${price}р.`
    },
    montages() {
      // return this.$store.getters['montages/getMontagesForMontager']
      return this.user.montages || []
    },
    costMontages() {
      if (!this.montages || this.montages.length === 0) {
        return false
      }
      let price = 0
      this.montages.map(montage => {
        if (montage.montagersInfo[this.user.id] && montage.montagersInfo[this.user.id].costMontage) {
          price = price + Number(montage.montagersInfo[this.user.id].costMontage)
        }
      })
      return `${price}р.`
    },
    id() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser.id
      }
      return Number(this.$route.params.uid)
    },
    user() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser
      }
      return this.$store.getters['users/getUser'](this.id)
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    currentUser() {
      return this.$store.getters['user/getUser']
    }
  }
}
</script>

<style lang="scss">
.custom-expan-panel {
  border-radius: 0 !important;

  .v-expansion-panel-header--active {
    min-height: 50px !important;
  }

  &_cont .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
