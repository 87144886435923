import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanel,{staticClass:"custom-expan-panel"},[_c(VExpansionPanelHeader,{staticStyle:{"font-size":"1em"}},[_vm._v(" Почасовая работа "),(_vm.costHourlyWorks)?_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.costHourlyWorks))]):_vm._e()]),_c(VExpansionPanelContent,{staticClass:"custom-expan-panel_cont"},[_c(VDivider),(_vm.hourlyWorks.length === 0)?_c('div',{staticClass:"pa-3 pl-6"},[_vm._v(" Нет работы ")]):_vm._e(),(_vm.hourlyWorks.length > 0)?_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Дата")]),_c('th',{staticClass:"text-left"},[_vm._v("Часы")]),_c('th',{staticClass:"text-left"},[_vm._v("Что сделал")]),_c('th',{staticClass:"text-left"},[_vm._v("Итого")])])]),_c('tbody',_vm._l((_vm.hourlyWorks),function(task){return _c('tr',{key:task.id,staticClass:"tableItemList",attrs:{"set":_vm.ifHPWM = _vm.$ifHourPayWorkMasters(task, _vm.user)}},[_c('td',{staticStyle:{"min-width":"85px"}},[_vm._v(_vm._s(_vm.$formatDate(new Date(task.date))))]),_c('td',{staticStyle:{"min-width":"50px"}},[(task.hourPayWorkMasters)?_c('span',[_vm._v(" "+_vm._s(_vm.ifHPWM.allHour)+"ч. ")]):_c('span',[_vm._v(" "+_vm._s(task.numberOfHours)+"ч. ")])]),_c('td',{staticStyle:{"max-width":"110px"}},[_vm._v(_vm._s(_vm.$sliceStr(_vm.$getStrTasks(task.tasks), 20)))]),_c('td',[(task.hourPayWorkMasters)?_c('span',[_vm._v(" "+_vm._s(_vm.ifHPWM.cost)+"р. ")]):(task.numberOfHours && task.rate)?_c('span',[_vm._v(" "+_vm._s(Number(task.numberOfHours) * task.rate)+"р. ")]):_c('span',[_vm._v("Ничего")])])])}),0)]},proxy:true}],null,false,2973994809)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }