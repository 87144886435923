import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',[_c('Header',{attrs:{"user":_vm.user,"currentUser":_vm.currentUser}}),_c(VTabs,{staticClass:"mobTasksPage_tabs",attrs:{"fixed-tabs":"","color":"#rgb(224, 166, 119)","background-color":"#rgb(224, 166, 119)"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_vm._l((_vm.tabs),function(item){return _c(VTab,{key:item.key},[_vm._v(" "+_vm._s(item.text)+" ")])})],2),_c(VTabsItems,{staticStyle:{"background-color":"#424242"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c(VTabItem,{key:item.key},[(item.key === 'work')?_c('TabWork',{attrs:{"user":_vm.user,"currentUser":_vm.currentUser}}):_vm._e(),(item.key === 'finance')?_c('TabFinance',{attrs:{"user":_vm.user,"currentUser":_vm.currentUser}}):_vm._e()],1)}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }