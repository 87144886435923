<template>
  <v-expansion-panel class="custom-expan-panel">
    <v-expansion-panel-header 
      :hide-actions="currentUser.role === 'admin'"
      style="font-size: 1em;"
      class="d-flex justify-space-between"
      :class="{
        'py-0': currentUser.role === 'admin',
        'pr-5': currentUser.role === 'admin'
      }"
    >
      <span>
        Премии 
        <span v-if="summPremiums" class="pl-1">{{ summPremiums }}</span>
      </span>
      <v-btn max-width="38" v-if="currentUser.role === 'admin'" icon @click.stop="createPremiums()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="custom-expan-panel_cont">
      <v-divider />
      <div 
        v-if="premiums.length === 0"
        class="pa-3 pl-6"
      >
        Нет премий
      </div>
      <v-simple-table v-if="premiums.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дата</th>
              <th class="text-left">Сумма</th>
              <th class="text-left">Коммент.</th>
              <th v-if="currentUser.role === 'admin'" class="text-center">Действ.</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="premium in premiums"
              :key="premium.id"
              class="tableItemList"
            >
              <td style="min-width: 80px;">{{ $formatDate(new Date(premium.date)) }}</td>
              <td>{{ premium.cost }}р.</td>
              <td class="pl-6">
                <v-btn icon @click.stop="openComment(premium.comment)">
                  <v-icon color="grey">
                    mdi-comment-text-outline
                  </v-icon>
                </v-btn>
              </td>
              <td v-if="currentUser.role === 'admin'" class="text-center">
                <v-btn @click.stop="deleteItem(prepayment)" icon color="#bf4037">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { usersCollection } from '@/plugins/firebase'
import FinanceComment from '@/components/Modals/FinanceComment.vue'
import Confirm from  '@/components/Modals/Confirm'
import Text from '@/components/Modals/Text.vue'

export default {
  components: {},
  props: {
    currentUser: {
      type: Object,
      default: {}
    },
    user: {
      type: Object,
      default: {}
    }
  },
  data: () => ({}),
  methods: {
    async deleteItem(data) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление премии`,
          text: `Действительно хотите удалить премию за ${this.$formatDate(new Date(data.date))}?`
        }
      })

      if (res) {
        await this.$store.dispatch('premiums/deletePremiums', data)
      }
    }, 
    async createPremiums() {
      let finObj = await this.$showModal({
        component: FinanceComment,
        isPersistent: false,
        props: {
          title: 'Новая премия',
          event: 'Выписать'
        }
      })

      if (finObj) {
        this.$store.dispatch('premiums/createPremiums', {
          cost: Number(finObj.summ),
          comment: finObj.comment,
          user: usersCollection.doc(this.user.id)
        })
      }
    },
    openComment(comment) {
      this.$showModal({
        component: Text,
        isPersistent: true,
        props: {
          text: comment
        }
      })
    }
  },
  computed: {
    premiums() {
      return this.$store.getters['premiums/getPremiums']
      // return this.user.premiums || []
    },
    summPremiums() {
      if (!this.premiums || this.premiums.length === 0) {
        return false
      }
      let price = 0
      this.premiums.map(premium => {
        if (premium && premium.cost) {
          price = price + Number(premium.cost)
        }
      })
      return `${price}р.`
    }
  }
}
</script>