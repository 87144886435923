<template>
  <v-expansion-panels style="border-radius: 0px;">
    <Prepayments :user="user" :currentUser="currentUser" />
    <Deductions :user="user" :currentUser="currentUser" />
    <Premiums :user="user" :currentUser="currentUser" />
  </v-expansion-panels>
</template>

<script>
import Prepayments from './Components/Prepayments.vue'
import Deductions from './Components/Deductions.vue'
import Premiums from './Components/Premiums.vue'

export default {
  components: {
    Prepayments,
    Deductions,
    Premiums
  },
  props: {
    currentUser: {
      type: Object,
      default: {}
    },
    user: {
      type: Object,
      default: {}
    }
  },
  data: () => ({}),
  methods: {},
  computed: {}
}
</script>
