import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanel,{staticClass:"custom-expan-panel"},[_c(VExpansionPanelHeader,{staticClass:"d-flex justify-space-between",class:{
      'py-0': _vm.currentUser.role === 'admin',
      'pr-5': _vm.currentUser.role === 'admin'
    },staticStyle:{"font-size":"1em"},attrs:{"hide-actions":_vm.currentUser.role === 'admin'}},[_c('span',[_vm._v(" Удержания "),(_vm.summDeductions)?_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.summDeductions))]):_vm._e()]),(_vm.currentUser.role === 'admin')?_c(VBtn,{attrs:{"max-width":"38","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.createDeductions()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1):_vm._e()],1),_c(VExpansionPanelContent,{staticClass:"custom-expan-panel_cont"},[_c(VDivider),(_vm.deductions.length === 0)?_c('div',{staticClass:"pa-3 pl-6"},[_vm._v(" Нет удержаний ")]):_vm._e(),(_vm.deductions.length > 0)?_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Дата")]),_c('th',{staticClass:"text-left"},[_vm._v("Сумма")]),_c('th',{staticClass:"text-left"},[_vm._v("Коммент.")]),(_vm.currentUser.role === 'admin')?_c('th',{staticClass:"text-center"},[_vm._v("Действ.")]):_vm._e()])]),_c('tbody',_vm._l((_vm.deductions),function(deduction){return _c('tr',{key:deduction.id,staticClass:"tableItemList"},[_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v(_vm._s(_vm.$formatDate(new Date(deduction.date))))]),_c('td',[_vm._v(_vm._s(deduction.cost)+"р.")]),_c('td',{staticClass:"pl-6"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openComment(deduction.comment)}}},[_c(VIcon,{attrs:{"color":"grey"}},[_vm._v(" mdi-comment-text-outline ")])],1)],1),(_vm.currentUser.role === 'admin')?_c('td',{staticClass:"text-center"},[_c(VBtn,{attrs:{"icon":"","color":"#bf4037"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(_vm.prepayment)}}},[_c(VIcon,[_vm._v("mdi-trash-can-outline")])],1)],1):_vm._e()])}),0)]},proxy:true}],null,false,1032165779)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }